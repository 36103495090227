import { GetEndpoint } from '../../src/library/ajax/getEndpoint';
import { PostEndpoint } from '../../src/library/ajax/postEndpoint';
import { SecurityCardType, SystemHeader } from '../types/emksKeySystemController';
import { EmksAddress } from '../types/keySystemData/personalData';
import {EmksCustomerSegment} from "../../../user-service";

// The following endpoints can be tested using swagger:
// http://dev-storybook-dormakaba.namics-test.com/dormakabacommercewebservices/swagger/swagger-ui.html

// Datastructure description SecurityCardWsDto
// https://wiki.namics.com/display/DOKA/23+-+Interfaces+OCC#id-23-InterfacesOCC-PersonalDataWsDto

export type SecurityCardGroup = {
	cylinderLockingPositions: Array<LockingPosition>;
	keyLockingPositions: Array<LockingPosition>;
	addresses: Array<EmksAddress>;
	securityCardNumber: string;
	securityCardType: SecurityCardType;
	securityCardGroupId: string;
	skipKeyLockingPositions: boolean;
	dirty: boolean;
	systemHeaderInformation: SystemHeaderInformation;
	newGroupUuid: string;
};

export type SystemHeaderInformation = { category: string; customerNotes: string };

export type LockingPosition = {
	lockingPositionId: string;
	notes: string;
	piecesOrdered: number;
	piecesExisting: number;
};

// administration controller
export const getAdministrationEntries = GetEndpoint.create<
	{
		systemCode: string;
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	{
		securityCards: Array<SecurityCardGroup>;
		currentSecurityCard: string;
	}
	/*
    This endpoint will always return an empty response
    and an error code if the authentication fails
  	*/
>({
	url: ({ systemCode, hybrisStore, user, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/keysystems/${systemCode}/administration?rootB2BUnit=${rootB2BUnit}`,
	cacheEnabled: true,
	options: ({ token }) => ({ headerOptions: { authToken: token } }),
});

export const postAdministrationEntries = PostEndpoint.create<
	{
		systemCode: string;
		token: string;
		hybrisStore: string;
		user: string;
		rootB2BUnit: string;
	},
	{
		securityCards: Array<SecurityCardGroup>;
		deliveryAddress?: EmksAddress;
	},
	{}
	/*
    This endpoint will always return an empty response
    and an error code if the authentication fails
  	*/
>({
	url: ({ systemCode, hybrisStore, user, rootB2BUnit }) =>
		`/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/keysystems/${systemCode}/administration?rootB2BUnit=${rootB2BUnit}`,
	options: ({ token }) => ({
		afterSuccess: () => {
			getAdministrationEntries.clearCache();
			getCurrentSecurityCard.clearCache();
		},
		headerOptions: { authToken: token },
	}),
});

/**
 * Checks if the current authentication token is still valid
 */
export const getCurrentSecurityCard = GetEndpoint.create<
	{
		/* Language e.g. 'en' */
		language: string;
		systemCode: string;
		token: string;
		hybrisStore: string;
		user: string;
		headPosition?: string;
		rootB2BUnit: string;
		controlBar?: boolean;
		customerSegment?: EmksCustomerSegment;
	},
	SecurityCardGroup
>({
	url: ({ hybrisStore, systemCode, language, user, headPosition, rootB2BUnit, controlBar, customerSegment }) => {
		const headPositionParam = headPosition ? `&headPositionCode=${headPosition}` : '';
		const zkPartnerParam = headPosition ? '' : '&zkPartner=';
		const customerSegmentParam = customerSegment ? `&customerSegment=${customerSegment}` : '';
		const controlBarParam = controlBar ? controlBar : false;
		return `/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/keysystems/${systemCode}/administration/currentsecuritycard?lang=${language}&rootB2BUnit=${rootB2BUnit}${customerSegmentParam}${headPositionParam}${zkPartnerParam}&controlBar=${controlBarParam}`;
	},
	cacheEnabled: true,
	options: ({ token }) => ({
		headerOptions: { authToken: token },
	}),
});

/**
 * Checks if the current authentication token is still valid
 */
export const updateCurrentSecurityCard = PostEndpoint.create<
	{
		/* Language e.g. 'en' */
		language: string;
		systemCode: string;
		token: string;
		hybrisStore: string;
		user: string;
		headPosition?: string;
		rootB2BUnit: string;
		addressType?: string,
	},
	SecurityCardGroup,
	void
>({
	url: ({ hybrisStore, systemCode, language, user, headPosition, rootB2BUnit, addressType }) => {
		const headPositionParam = headPosition ? `&headPositionCode=${headPosition}` : '';
		const zkPartnerParam = headPosition ? '' : '&zkPartner=';
		const addressTypeParam = addressType ? `&addressType=${addressType}` : '';
		return `/dormakabacommercewebservices/v2/${hybrisStore}/emks/users/${user}/keysystems/${systemCode}/administration/currentsecuritycard?lang=${language}&rootB2BUnit=${rootB2BUnit}${headPositionParam}${zkPartnerParam}${addressTypeParam}`;
	},
	options: ({ token }) => ({
		headerOptions: { authToken: token },
		afterSuccess: () => {
			getCurrentSecurityCard.clearCache();
		},
	}),
});
